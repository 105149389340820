import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import TableCard from "../../../shared/TableCard/TableCard";
import { useHistory } from "react-router-dom";
import { confirmPopup } from "primereact/confirmpopup";
import { useDispatch } from "react-redux";
import { deleteDiscount } from "../../../redux/actions/discountAction";
import { classNames } from "primereact/utils";

function DiscountTable({ allDiscounts }) {
    let history = useHistory();
    let dispatch = useDispatch();

    const deleteRow = (e, id) => {
        confirmPopup({
            target: e.currentTarget,
            message: "Are you sure you want to delete?",
            icon: "pi pi-exclamation-triangle",
            accept: () => {
                dispatch(deleteDiscount(id));
            },
            reject: () => { },
        });
    };

    const actions = (data) => {
        return (
            <div className="flex justify-content-around">
                <i className="pi pi-eye cursor-pointer m-1" onClick={() => history.push(`/discounts/view/${data._id}`)}></i>
                <i className="pi pi-pencil cursor-pointer m-1" onClick={() => history.push(`/discounts/edit/${data._id}`)}></i>
                <i className="pi pi-trash cursor-pointer m-1" onClick={(e) => deleteRow(e, data._id)}></i>
            </div>
        );
    };

    const approvedBodyTemplate = (rowData) => {
        return (
            <i
                className={classNames("pi", {
                    "true-icon pi-check-circle": rowData.isActive,
                    "false-icon pi-times-circle": !rowData.isActive,
                })}
            ></i>
        );
    };

    const navigateToSubscription = (rowData) => {
        if (rowData.subscriptionType) {
            return (
                <div style={{ cursor: "pointer" }} onClick={() => history.push(`/subscription-types/view/${rowData.subscriptionType._id}`)}>
                    {rowData.subscriptionType?.subscriptionName}
                </div>
            );
        } else {
            return null
        }
    };

    return (
        <>
            <TableCard title="Discounts List" buttonTitle="Add Discount" linkTo={`/discounts/add`}>
                <DataTable paginator value={allDiscounts} showGridlines filterDisplay="menu" rows={10} emptyMessage="No Discount found.">
                    <Column field="discountCode" sortable header="Discount Code"></Column>
                    <Column field="discountType" sortable header="Discount Type"></Column>
                    <Column
                        // field="subscriptionType.subscriptionName"
                        sortable
                        field="subscriptionType.subscriptionName"
                        header="Discount For Subscription"
                        body={navigateToSubscription}
                    ></Column>
                    <Column body={actions} style={{ width: "80px" }} header="Action"></Column>
                </DataTable>
            </TableCard>
        </>
    );
}
export default DiscountTable;
