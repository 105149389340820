import React from "react";
import CustomCard from "../../../shared/Card/CustomCard";
import SubscriptionTypeForm from "./SubscriptionTypeForm";

export default function EditSubscriptionType() {
    return (
        <CustomCard>
            <SubscriptionTypeForm type="EDIT" />
        </CustomCard>
    );
}
