import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import TableCard from "../../../shared/TableCard/TableCard";
import { useHistory } from "react-router-dom";

function UserSubscriptionTable({ allUserSubscriptions }) {
    let history = useHistory();

    const navigateToVendor = (rowData) => {
        if (rowData.vendor) {
            return (
                <div style={{ cursor: "pointer" }} onClick={() => history.push(`/vendors/view/${rowData.vendor._id}`)}>
                    {rowData.vendor.businessName}
                </div>
            );
        } else {
            return "N/A";
        }
    };

    const navigateToSubscriptionType = (rowData) => {
        if (rowData.subscriptionType) {
            return (
                <div style={{ cursor: "pointer" }} onClick={() => history.push(`/subscription-types/view/${rowData.subscriptionType._id}`)}>
                    {rowData.subscriptionType.subscriptionName}
                </div>
            );
        } else {
            return "N/A";
        }
    };

    const navigateToDiscount = (rowData) => {
        if (rowData.discount) {
            return (
                <div style={{ cursor: "pointer" }} onClick={() => history.push(`/discounts/view/${rowData.discount._id}`)}>
                    {rowData.discount.discountCode}
                </div>
            );
        } else {
            return "N/A";
        }
    };

    const statusBody = (rowData) => {
        if (rowData.status) {
            return (
                <div>
                    {rowData.status === "canceled" ? (
                        <strong style={{ color: "rgb(255 0 0 / 65%)" }}>Cancelled</strong>
                    ) : rowData.status === "pending" ? (
                        <strong>Pending</strong>
                    ) : rowData.status === "active" ? (
                        <strong style={{ color: "rgb(0 128 0 / 60%)" }}>Active</strong>
                    ) : null}
                </div>
            );
        } else {
            return "N/A";
        }
    };

    return (
        <>
            <TableCard title="User Subscription List">
                <DataTable
                    paginator
                    value={allUserSubscriptions}
                    showGridlines
                    filterDisplay="menu"
                    rows={10}
                    emptyMessage="No User Subscription found."
                >
                    <Column sortable header="Vendor's Business Name" field="vendor.businessName" body={navigateToVendor}></Column>
                    <Column sortable header="Subscription Type" field="subscriptionType.subscriptionName" body={navigateToSubscriptionType}></Column>
                    <Column sortable header="Discount" field="discount.discountCode" body={navigateToDiscount}></Column>
                    <Column sortable header="Status" field="status" body={statusBody} />
                </DataTable>
            </TableCard>
        </>
    );
}
export default UserSubscriptionTable;
