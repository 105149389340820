import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useState } from "react";
import PrimaryButton, { PrimaryButtonOutlined } from "../../shared/Button/PrimaryButton";
import { Dialog } from "primereact/dialog";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CustomDropDown, CustomTextArea } from "../../shared/Input/AllInputs";

export const NewBussinessList = () => {
    const [deleteView, setDeleteView] = useState(false);
    let history = useHistory();

    function NavigateToProfile() {
        history.push("/vendors/vendor-profile");
    }
    const actions = (d) => {
        return (
            <div className="flex align-items-center justify-content-around">
                <i className="pi pi-check text-green-500" />
                <i className="pi pi-times text-red-500"  onClick={() => setDeleteView(true)} />
                <i className="pi pi-eye cursor-pointer text-blue-500" onClick={NavigateToProfile}></i>
            </div>
        );
    };
    const enquiryData = [
        {
            sno: "1",
            bussinessname: "Matrimonial",
            email: "testing@yopmail.com",
        },
    ];
    return (
        <>
            <div className="col-12 xl:col-6">
                <div className="card h-full">
                    <h3>New Business Registrations</h3>
                    <DataTable
                        paginator
                        value={enquiryData}
                        selectionMode="checkbox"
                        showGridlines
                        filterDisplay="menu"
                        rows={10}
                        emptyMessage="No customer found."
                    >
                        <Column field="sno" header="S. No."></Column>
                        <Column field="bussinessname" header="Business Name"></Column>
                        <Column field="email" header="Email"></Column>

                        <Column body={actions} header="Action"></Column>
                    </DataTable>
                </div>
            </div>
            <Dialog visible={deleteView} style={{ width: "30vw" }} onHide={() => setDeleteView(false)}>
                <div className="grid">
                    <CustomDropDown label="Reason" col={12} />
                    <CustomTextArea resizable={false}/>
                    <PrimaryButton label="Submit" extraClassName="w-full" />
                </div>
            </Dialog>
        </>
    );
};
