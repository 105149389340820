import React from "react";

import BgImage from "../../assets/images/bg-image.jpg";

export default function Background({ children, heading }) {
  return (
    <div
      className="main-background relative"
      style={{
        background: `#008DBB`,
        minHeight: "100vh",
      }}
    >
      <div className="p-3 h-full relative">{children}</div>
    </div>
  );
}
