import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import TableCard from "../../shared/TableCard/TableCard";
import { useHistory } from "react-router-dom";
import { hostDelete } from "../../redux/actions/hostAction";
import { confirmPopup } from "primereact/confirmpopup";
import { useDispatch } from "react-redux";
import { classNames } from "primereact/utils";

export const HostTable = ({ allHost }) => {
    let history = useHistory();
    let dispatch = useDispatch();

    const actions = (data) => {
        return (
            <div className="flex justify-content-around">
                <i className="pi pi-eye cursor-pointer" onClick={() => history.push(`/host/view/${data._id}`)}></i>
                <i className="pi pi-trash cursor-pointer" onClick={(e) => deleteRow(e, data._id)}></i>
            </div>
        );
    };

    const deleteRow = (e, id) => {
        confirmPopup({
            target: e.currentTarget,
            message: "Are you sure you want to delete?",
            icon: "pi pi-exclamation-triangle",
            accept: () => {
                dispatch(hostDelete(id));
            },
            reject: () => {},
        });
    };

    return (
        <>
            <TableCard title="Host List">
                <DataTable
                    paginator
                    value={allHost}
                    // selection={selectedRow}
                    // onSelectionChange={(e) => setSelectedRow(e.value)}
                    showGridlines
                    // filter={filters}
                    filterDisplay="menu"
                    rows={10}
                    // loading={loading}
                    emptyMessage="No customer found."
                >
                    <Column field="name" sortable header="Name"></Column>
                    <Column field="user.email" sortable header="Email"></Column>

                    <Column body={actions} style={{ width: "80px" }} header="Action"></Column>
                </DataTable>
            </TableCard>
        </>
    );
};
