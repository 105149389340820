export default class Constants {
    static END_POINT = {
        LOGIN: "/login",
        PROFILE: "/profile",
        FORGET_PASSWORD: "/forget-password",
        UPDATE_PROFILE: "/update",
        RESET_PASSWORD: "/change-forget-password",
        CHANGE_PASSWORD: "/change-password",

        //Dashborad

        GET_USERS_COUNT: "/user/count",
        NOTIFICATION: "/notification",
        CHANGE_APP_DESC: "/constant",

        //Users
        CUSTOMER: "/user/",
        ENABLE_DISABLE_CUSTOMER: "/block-unblock-user/",

        LOCATION: "/location/",

        //Location Menu

        CATEGORY: "/category/",

        //Products

        PRODUCTS: "/product/",

        ORDERS: "/order/",
        CHANGE_ORDER_STATUS: "/change-order-status/",

        //Employee

        EMPLOYEE: "/employee/",
        ENABLE_DISABLE_EMPLOYEE: "/enable-disable-employee/",

        //Ingredients

        INGREDIENTS: "/ingredient/",

        //Add -On

        ADD_ON: "/addon/",
        ENABLE_DISABLE_ADD_ON: "/enable-disable-add-on/",

        //Tracks

        TRACKS: "/track/",

        PROGRAMS: "/program/",

        //Plans

        PLANS: "/plan/",

        // Vendors
        VENDORS: "/vendors/",
        APPROVE_VENDOR: "/vendors/approve-vendor",

        // Vendors
        VENDOR_CATEGORIES: "/vendor-type/",

        // Amenities
        AMENITIES: "/amenities/",

        // Hosts
        HOSTS: "/hosts/",

        // Subscription Types
        SUBSCRIPTION_TYPES: "/subscription-type/",

        // Discounts
        DISCOUNTS: "/discount/",

        // User Subscriptions
        USER_SUBSCRIPTIONS: "/subscription/",
        //Features
        FEATURE: "/feature/",
        // Hosts
        UPLOAD_FILE: "/file-upload",
        UPLOAD_MULTIPLE_FILES: "/upload-files",
    };
}
