import api from "../../services/api";
import Constants from "../../services/constant";
import { hideLoaderAction, showLoaderAction } from "./loaderAction";
import { types } from "../types/types";
import { showToast } from "./toastAction";

const getUserSubscriptions = () => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.USER_SUBSCRIPTIONS);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.ALL_USER_SUBSCRIPTION,
                payload: res.data,
            });
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

const userSubscriptionDetails = (id, returnData) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", `${Constants.END_POINT.USER_SUBSCRIPTIONS}${id}`);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.USER_SUBSCRIPTION_DETAILS,
                payload: res.data,
            });
        }
        if (returnData) {
            returnData(res.data);
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

export { getUserSubscriptions, userSubscriptionDetails };
