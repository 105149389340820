import { types } from "../types/types";

const intitalState = {
    allDiscounts: [],
};

const discountsReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.ALL_DISCOUNT:
            return {
                ...state,
                allDiscounts: action.payload,
            };
        case types.DISCOUNT_DETAILS:
            return {
                ...state,
                discount: action.payload,
            };
        case types.DELETE_DISCOUNT:
            return {
                ...state,
                successDelete: true,
            };
        default:
            return { ...state };
    }
};

export default discountsReducer;
