export const types = {
    SHOW_TOAST: "SHOW_TOAST",
    SHOW_LOADER: "SHOW_LOADER",
    HIDE_LOADER: "HIDE_LOADER",
    SHOW_SIDEBAR_LOADER: "SHOW_SIDEBAR_LOADER",
    HIDE_SIDEBAR_LOADER: "HIDE_SIDEBAR_LOADER",
    USER_DETAIL: "USER_DETAIL",
    CHANGE_CUSTOMERS: "CHANGE_CUSTOMERS",
    CHANGE_LOCATIONS: "CHANGE_LOCATIONS",
    CHANGE_LOCATION_NAME: "CHANGE_LOCATION_NAME",
    CHANGE_EMPLOYEES: "CHANGE_EMPLOYEES",
    CHANGE_LOCATIONS_MENU: "CHANGE_LOCATIONS_MENU",
    CHANGE_PRODUCTS: "CHANGE_PRODUCTS",
    CHANGE_INGREDIENTS: "CHANGE_INGREDIENTS",
    CHANGE_ADDON: "CHANGE_ADDON",
    CHANGE_ORDERS_MENU: "CHANGE_ORDERS_MENU",
    CHANGE_TRACKS: "CHANGE_TRACKS",
    CHANGE_PROGRAMS: "CHANGE_PROGRAMS",
    UPDATE_UPLOAD_PROGRESS: "UPDATE_UPLOAD_PROGRESS",
    CHANGE_PLANS: "CHANGE_PLANS",
    ALL_FEATURES: "ALL_FEATURES",

    // Vendors
    ALL_VENDORS: "ALL_VENDORS",
    VENDOR_DETAILS: "VENDOR_DETAILS",
    DELETE_VENDOR: "DELETE_VENDOR",

    APPROVE_VENDOR: "APPROVE_VENDOR",

    // Amenities
    AMENITIES_ADD: "AMENITIES_ADD",
    AMENITIES: "AMENITIES",
    AMENITIES_DETAILS: "AMENITIES_DETAILS",
    UPDATE_AMENITIES: "UPDATE_AMENITIES",
    DELETE_AMENITIES: "DELETE_AMENITIES",

    // Vendor Categories
    VENDOR_CATEGORIES: "VENDOR_CATEGORIES",
    VENDOR_CATEGORY_DETAILS: "VENDOR_CATEGORY_DETAILS",
    DELETE_VENDOR_CATEGORY: "DELETE_VENDOR_CATEGORY",

    // Hosts
    ALL_HOSTS: "ALL_HOSTS",
    HOST_DETAILS: "HOST_DETAILS",
    DELETE_HOST: "DELETE_HOST",

    // Subscription Type
    SUBSCRIPTION_TYPE_ADD: "SUBSCRIPTION_TYPE_ADD",
    ALL_SUBSCRIPTION_TYPE: "ALL_SUBSCRIPTION_TYPE",
    SUBSCRIPTION_TYPE_DETAILS: "SUBSCRIPTION_TYPE_DETAILS",
    UPDATE_SUBSCRIPTION_TYPE: "UPDATE_SUBSCRIPTION_TYPE",
    DELETE_SUBSCRIPTION_TYPE: "DELETE_SUBSCRIPTION_TYPE",

    // Discount
    ALL_DISCOUNT: "ALL_DISCOUNT",
    DISCOUNT_DETAILS: "DISCOUNT_DETAILS",
    DELETE_DISCOUNT: "DELETE_DISCOUNT",

    // User Subscription
    ALL_USER_SUBSCRIPTION: "ALL_USER_SUBSCRIPTION",
    USER_SUBSCRIPTION_DETAILS: "USER_SUBSCRIPTION_DETAILS",
    DELETE_USER_SUBSCRIPTION: "DELETE_USER_SUBSCRIPTION",
};
