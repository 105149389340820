import React, { useEffect, useState } from "react";
import { CustomForm, CustomInput, CustomInputSwitch } from "../../shared/Input/AllInputs";
import CustomImageInput from "../../shared/Input/CustomImageInput";
import PrimaryButton from "../../shared/Button/PrimaryButton";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { addVendorCategoryAction, editVendorCategoryAction, vendorCategoryDetails } from "../../redux/actions/vendorCategoryAction";
import { allValidations } from "../../utils/formValidations";
import { showFormErrors } from "../../utils/commonFunctions";

const VendorCategoryForm = ({ type }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { id } = useParams();

    const [data, setData] = useState({
        name: "",
        icon: "",
        image: "",
        isActive: true,
        isDeleted: false,
    });
    const ignoreFields = ["image", "icon"];

    useEffect(() => {
        if (id) {
            dispatch(
                vendorCategoryDetails(id, (data) => {
                    data.image = data.image ? [data.image] : "";
                    data.icon = data.icon ? [data.icon] : "";
                    setData(data);
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, dispatch]);

    const [loading, setLoading] = useState(false);

    const handleChange = ({ name, value }) => {
        if (type !== "VIEW") {
            const formErrors = allValidations(name, value, data);
            setData((prev) => ({ ...prev, [name]: value, formErrors }));
        }
    };

    const onSubmit = async () => {
        if (showFormErrors(data, setData, ignoreFields)) {
            if (type === "ADD") {
                dispatch(addVendorCategoryAction(data, setLoading, history));
            }
            if (type === "EDIT") {
                dispatch(editVendorCategoryAction(id, data, setLoading, history));
            }
        }
    };

    return (
        <CustomForm style={type === "VIEW" ? { pointerEvents: "none" } : {}}>
            <CustomImageInput
                label="Upload Image"
                data={data}
                onFilesChange={handleChange}
                name="image"
                editable={type !== "VIEW" ? true : false}
                removeable
                col={6}
            />
            <CustomImageInput
                label="Upload Icon"
                data={data}
                onFilesChange={handleChange}
                name="icon"
                editable={type !== "VIEW" ? true : false}
                removeable
                col={6}
            />
            <CustomInput label="Name" name="name" data={data} onChange={handleChange} col={12} />
            <CustomInputSwitch label="Active" name="isActive" onChange={handleChange} data={data} />
            {type !== "VIEW" && <PrimaryButton label="Save Category" loading={loading} onClick={onSubmit} col={12} />}
        </CustomForm>
    );
};

export default VendorCategoryForm;
