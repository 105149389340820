import React from "react";
import CustomCard from "../../shared/Card/CustomCard";
import VendorCategoryForm from "./VendorCategoryForm";

export default function EditVendorCategory() {
    return (
        <CustomCard>
            <VendorCategoryForm type="EDIT" />
        </CustomCard>
    );
}
