import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { types } from "../../redux/types/types";
import { getVendorCategories } from "../../redux/actions/vendorCategoryAction";
import { VendorCategoryTable } from "../VendorCategory/VendorCategoryTable";

const VendorCategories = () => {
    const dispatch = useDispatch();

    const { allVendorCategories } = useSelector((state) => state.vendorCategories);
    const { successDelete } = useSelector((state) => state.vendorCategories);

    useEffect(() => {
        dispatch(getVendorCategories());
    }, [dispatch, successDelete]);

    return (
        <div>
            <VendorCategoryTable allVendorCategories={allVendorCategories} />
        </div>
    );
};
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};
export default React.memo(VendorCategories, comparisonFn);
