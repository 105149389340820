import React, { useEffect } from "react";
import { SubscriptionTypeTable } from "./SubscriptionTypeTable";
import { useDispatch, useSelector } from "react-redux";
import { getSubscriptionTypes } from "../../../redux/actions/subscriptionTypeAction";

const SubscriptionTypes = () => {
    const dispatch = useDispatch();
    const { allSubscriptionTypes, successDelete } = useSelector((state) => state.subscriptionTypes);
    useEffect(() => {
        dispatch(getSubscriptionTypes());
    }, [dispatch, successDelete]);

    return (
        <div>
            <SubscriptionTypeTable heading="Subscription Types" allSubscriptionType={allSubscriptionTypes} />{" "}
        </div>
    );
};
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};
export default React.memo(SubscriptionTypes, comparisonFn);
