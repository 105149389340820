import { types } from "../types/types";

const intitalState = {
    allUserSubscriptions: [],
};

const userSubscriptionsReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.ALL_USER_SUBSCRIPTION:
            return {
                ...state,
                allUserSubscriptions: action.payload,
            };
        case types.USER_SUBSCRIPTION_DETAILS:
            return {
                ...state,
                userSubscription: action.payload,
            };
        case types.DELETE_USER_SUBSCRIPTION:
            return {
                ...state,
                successDelete: true,
            };
        default:
            return { ...state };
    }
};

export default userSubscriptionsReducer;
