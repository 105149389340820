import api from "../../services/api";
import Constants from "../../services/constant";
import { hideLoaderAction, showLoaderAction } from "./loaderAction";
import { types } from "../types/types";
import { showToast } from "./toastAction";

const getVendors = () => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.VENDORS);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.ALL_VENDORS,
                payload: res.data,
            });
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

const vendorDetails = (id, returnData) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", `${Constants.END_POINT.VENDORS}${id}`);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.VENDOR_DETAILS,
                payload: res.data,
            });
        }
        if (returnData) {
            returnData(res.data);
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

const approveVendor = (id, data, history) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("post", `${Constants.END_POINT.APPROVE_VENDOR}`, data);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.APPROVE_VENDOR,
                payload: res.data,
            });
            history.push("/vendors");
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

const deleteVendor = (id) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("delete", `${Constants.END_POINT.VENDORS}${id}`);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.DELETE_VENDOR,
            });
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

export { getVendors, vendorDetails, approveVendor, deleteVendor };
