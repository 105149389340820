const BASE_URL = "https://app.plantheknot.com/api/admin";
const HOST = "https://app.plantheknot.com/";

// const BASE_URL = "https://plantheknotapi.appdeft.in/api/admin";
// const HOST = "https://plantheknotapi.appdeft.in/";

// const BASE_URL = "http://localhost:1105/api/admin";
// const HOST = "http://localhost:1105/";

export { BASE_URL, HOST };
