import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import TableCard from "../../shared/TableCard/TableCard";
import { useHistory } from "react-router-dom";
import { confirmPopup } from "primereact/confirmpopup";
import { useDispatch } from "react-redux";
import { deleteVendor } from "../../redux/actions/vendorAction";
import { classNames } from "primereact/utils";
import moment from "moment";

export const VendorTable = ({ allVendor }) => {
    let history = useHistory();
    let dispatch = useDispatch();

    const deleteRow = (e, id) => {
        confirmPopup({
            target: e.currentTarget,
            message: "Are you sure you want to delete?",
            icon: "pi pi-exclamation-triangle",
            accept: () => {
                dispatch(deleteVendor(id));
            },
            reject: () => { },
        });
    };

    const actions = (data) => {
        return (
            <div className="flex justify-content-around">
                <i className="pi pi-eye cursor-pointer" onClick={() => history.push(`/vendors/view/${data._id}`)}></i>
                <i className="pi pi-trash cursor-pointer" onClick={(e) => deleteRow(e, data._id)}></i>
            </div>
        );
    };

    const approvedBodyTemplate = (rowData) => {
        return (
            <i
                className={classNames("pi", {
                    "true-icon pi-check-circle": rowData.isApproved,
                    "false-icon pi-times-circle": !rowData.isApproved,
                })}
            ></i>
        );
    };

    const vendorTypeTemplate = (rowData) => {
        return (
            <>{rowData.vendorType ? rowData.vendorType.name : 'N/A'}</>
        );
    };

    const vendorJoinedTemplate = (rowData) => {
        return (
            <>{rowData.user ? moment(rowData.user.time).format('LLL') : 'N/A'}</>
        );
    };

    return (
        <>
            <TableCard title="Vendor List">
                <DataTable paginator value={allVendor} showGridlines filterDisplay="menu" rows={10} emptyMessage="No vendor found.">
                    <Column field="businessName" sortable header="Business Name"></Column>
                    <Column field="user.email" sortable header="Email"></Column>
                    <Column sortable header="Vendor Type" body={vendorTypeTemplate}></Column>
                    <Column field="user.time" header="Joined" body={vendorJoinedTemplate} />
                    <Column field="user.isApproved" header="Status" dataType="boolean" body={approvedBodyTemplate} />
                    <Column body={actions} style={{ width: "80px" }} header="Actions"></Column>
                </DataTable>
            </TableCard>
        </>
    );
};
