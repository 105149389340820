import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import PrimaryButton from "../../shared/Button/PrimaryButton";

const EnquiryDesc = () => {
      
    const [visible, setVisible] = useState(false);
    const [position, setPosition] = useState('center');
    const show = (position) => {
        setPosition(position);
        setVisible(true);
    };
    
    const actions = (d) => {
        return (
            <div className="flex align-items-center justify-content-around">
                <i className="pi pi-eye text-blue-500 cursor-pointer" onClick={() => show('right')}></i>
                <a className=" p-2 border-round-lg" href="mailto:dj69679@gmail.com"><i className="pi pi-reply"></i></a>
            </div>
        );
    };
    const enquiryData = [
        {
            sno: "1",
            name: "testing",
            email: "testing@yopmail.com",
            usertype: "Vendor",
        }
    ] 
    return (
        <>
            <div className="col-12 xl:col-6">
                <div className="card h-full">
                    <h3>Recently Enquiry List</h3>
                    <DataTable
                    paginator
                    value={enquiryData}
                    selectionMode="checkbox"
                    showGridlines
                    filterDisplay="menu"
                    rows={10}
                    emptyMessage="No customer found."
                >
                    <Column field="sno" header="S. No."></Column>
                    <Column field="name" header="Name"></Column>
                    <Column field="email" header="Email"></Column>
                    <Column field="usertype" header="User Type" ></Column>
                    

                    <Column body={actions}  header="Action"></Column>
                </DataTable>
                </div>
            </div>
            <Dialog visible={visible} position={position} style={{ width: '50vw' }} onHide={() => setVisible(false)} draggable={false} resizable={false}>
            <p><span className="text-dark font-semibold">Name</span> Testing</p>
            <p><span className="text-dark font-semibold">Email</span> Testing@yopmail.com</p>
                <p className="m-0"><p className="text-dark font-semibold">Message</p> 
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                    consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
                    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
            </Dialog>
        </>
    );
};

export default EnquiryDesc;
