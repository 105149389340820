import React, { useEffect, useState } from "react";
import CustomCard from "../../../shared/Card/CustomCard";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { subscriptionTypeDetails } from "../../../redux/actions/subscriptionTypeAction";

export const ViewSubscriptionType = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const { subscriptionType } = useSelector((state) => state.subscriptionTypes);

    const [data, setData] = useState({
        profileImage: "",
    });

    useEffect(() => {
        dispatch(
            subscriptionTypeDetails(id, (data) => {
                setData(data);
            })
        );
    }, [dispatch, id]);

    return (
        <>
            <CustomCard title="Subscription Type" className="bg-white">
                {subscriptionType ? (
                    <>
                        <div className="grid">
                            <div className="md:col-4">
                                <label className="text-500 text-sm">Subscription Name</label>
                                <p className="text-dark font-medium">{subscriptionType.subscriptionName}</p>
                            </div>
                            <div className="md:col-4">
                                <label className="text-500 text-sm">Subscription Price</label>
                                <p className="text-dark font-medium">{subscriptionType.price ? subscriptionType.price : "N/A"}</p>
                            </div>
                            <div className="md:col-4">
                                <label className="text-500 text-sm">Offered Price</label>
                                <p className="text-dark font-medium">{subscriptionType.offeredPrice ? subscriptionType.offeredPrice : "N/A"}</p>
                            </div>
                            <div className="md:col-4">
                                <label className="text-500 text-sm">Type</label>
                                <p className="text-dark font-medium">{subscriptionType.subscriptionType}</p>
                            </div>
                            <div className="md:col-12">
                                <label className="text-500 text-sm">Description</label>
                                <p className="text-dark font-medium">{subscriptionType.subscriptionDescription}</p>
                            </div>
                            {subscriptionType.features && subscriptionType.features.length > 0 && (
                                <div className="md:col-12">
                                    <label className="text-500 text-sm">Features</label>
                                    {subscriptionType.features.map((feature) => (
                                        <>
                                            <p className="text-dark font-medium">* {feature}</p>
                                        </>
                                    ))}
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <div>Subscription Type not found</div>
                )}
            </CustomCard>
        </>
    );
};
