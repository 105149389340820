import React, { useEffect, useState } from "react";
import CustomCard from "../../../shared/Card/CustomCard";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { discountDetails } from "../../../redux/actions/discountAction";

function ViewDiscount() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const { discount } = useSelector((state) => state.discounts);

    const [data, setData] = useState({
        profileImage: "",
    });

    useEffect(() => {
        dispatch(
            discountDetails(id, (data) => {
                setData(data);
            })
        );
    }, [dispatch, id]);

    return (
        <>
            <CustomCard title="Discount" className="bg-white">
                {discount ? (
                    <>
                        <div className="grid">
                            <div className="md:col-4">
                                <label className="text-500 text-sm">Discount Code</label>
                                <p className="text-dark font-medium">{discount.discountCode}</p>
                            </div>
                            <div className="md:col-4">
                                <label className="text-500 text-sm">Discount Type</label>
                                <p className="text-dark font-medium">{discount.discountType}</p>
                            </div>
                            <div className="md:col-4">
                                <label className="text-500 text-sm">Discount Limit</label>
                                <p className="text-dark font-medium">{discount.discountLimit}</p>
                            </div>
                        </div>
                    </>
                ) : (
                    <div>Discount not found</div>
                )}
            </CustomCard>
        </>
    );
}

export default ViewDiscount;
