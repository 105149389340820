import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import EnquiryDesc from "../ChangeAppTitle/ChangeAppDescription";
import { NewBussinessList } from "./NewBussinessList";

export default function Dashboard({ name, history }) {
    const dispatch = useDispatch();

    const [usersCount, setUsersCount] = useState({
        activeUser: 0,
        inActiveUser: 0,
    });
    // useEffect(() => {
    //     dispatch(
    //         getUsersCount((data) => {
    //             setUsersCount(data);
    //         })
    //     );
    // }, [dispatch]);

    return (
        <div className="grid">
            <div className="col-12 lg:col-4 cursor-pointer" onClick={() => history.push("/customers")}>
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Active Vendor</span>
                            <div className="text-900 font-medium text-xl">{usersCount?.activeUser}</div>
                        </div>
                        <div
                            className="flex align-items-center justify-content-center bg-cyan-100 border-round"
                            style={{ width: "2.5rem", height: "2.5rem" }}
                        >
                            <i className="pi pi-users text-cyan-500 text-xl" />
                        </div>
                    </div>
                    <span className="text-500">Total Active Vendor</span>
                </div>
            </div>
            <div className="col-12 lg:col-4 cursor-pointer" onClick={() => history.push("/inactive-customers")}>
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Inactive Host</span>
                            <div className="text-900 font-medium text-xl">{usersCount?.inActiveUser}</div>
                        </div>
                        <div
                            className="flex align-items-center justify-content-center bg-red-100 border-round"
                            style={{ width: "2.5rem", height: "2.5rem" }}
                        >
                            <i className="pi pi-user-minus text-red-500 text-xl" />
                        </div>
                    </div>
                    <span className="text-500">Total Inactive Host</span>
                </div>
            </div>
            <div className="col-12 lg:col-4 cursor-pointer" onClick={() => history.push("/inactive-customers")}>
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">No. of Events</span>
                            <div className="text-900 font-medium text-xl">{usersCount?.inActiveUser}</div>
                        </div>
                        <div
                            className="flex align-items-center justify-content-center bg-green-300 border-round"
                            style={{ width: "2.5rem", height: "2.5rem" }}
                        >
                            <i className="pi pi-calendar text-white text-xl" />
                        </div>
                    </div>
                    <span className="text-500">Total Events</span>
                </div>
            </div>
            <EnquiryDesc />
            <NewBussinessList />
        </div>
    );
}
