import React from "react";
import CustomCard from "../../shared/Card/CustomCard";
import AmenitiesForm from "./AmenitiesForm";

export default function AddAmenities() {
    return (
        <CustomCard>
            <AmenitiesForm type="ADD" />
        </CustomCard>
    );
}
