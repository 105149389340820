import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAmenities } from "../../redux/actions/amenitiesAction";
import { AmenitiesTable } from "./AmenitiesTable";

const Amenities = () => {
    const dispatch = useDispatch();

    const { allAmenities } = useSelector((state) => state.amenities);
    const { successDelete } = useSelector((state) => state.amenities);

    useEffect(() => {
        dispatch(getAmenities());
    }, [dispatch, successDelete]);

    return (
        <div>
            <AmenitiesTable amenities={allAmenities} />
        </div>
    );
};
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};
export default React.memo(Amenities, comparisonFn);
