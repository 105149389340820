import React from "react";
import CustomCard from "../../../shared/Card/CustomCard";
import DiscountForm from "./DiscountForm";

export default function AddDiscount() {
    return (
        <CustomCard>
            <DiscountForm type="ADD" />
        </CustomCard>
    );
}
