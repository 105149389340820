import { types } from "../types/types";

const intitalState = {
    allVendor: [],
};

const vendorReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.ALL_VENDORS:
            return {
                ...state,
                allVendor: action.payload,
            };
        case types.VENDOR_DETAILS:
            return {
                ...state,
                vendor: action.payload,
            };
        case types.DELETE_VENDOR:
            return {
                ...state,
                successDelete: true,
            };
        default:
            return { ...state };
    }
};

export default vendorReducer;
