import api from "../../services/api";
import Constants from "../../services/constant";
import { hideLoaderAction, showLoaderAction } from "./loaderAction";
import { types } from "../types/types";
import { uploadFile } from "../../utils/uploadFiles";
import { showToast } from "./toastAction";

const getVendorCategories = () => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.VENDOR_CATEGORIES);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.VENDOR_CATEGORIES,
                payload: res.data,
            });
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

const vendorCategoryDetails = (id, returnData) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", `${Constants.END_POINT.VENDOR_CATEGORIES}${id}`);
    if (res.success) {
        dispatch({
            type: types.VENDOR_CATEGORY_DETAILS,
            payload: res.data,
        });
        if (returnData) {
            returnData(res.data);
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

const addVendorCategoryAction = (data, setLoading, history) => async (dispatch) => {
    dispatch(showLoaderAction());
    setLoading(true);

    data.image = await uploadFile(data.image);
    data.icon = await uploadFile(data.icon);

    const res = await api("post", Constants.END_POINT.VENDOR_CATEGORIES, data);
    if (res.success) {
        history.push("/vendors/categories");
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    setLoading(false);

    dispatch(hideLoaderAction());
};

const editVendorCategoryAction = (id, data, setLoading, history) => async (dispatch) => {
    dispatch(showLoaderAction());
    setLoading(true);
    data.image = await uploadFile(data.image);
    data.icon = await uploadFile(data.icon);

    const res = await api("put", `${Constants.END_POINT.VENDOR_CATEGORIES}${id}`, data);
    if (res.success) {
        history.push("/vendors/categories");
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    setLoading(false);
    dispatch(hideLoaderAction());
};

const deleteVendorCategory = (id) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("delete", `${Constants.END_POINT.VENDOR_CATEGORIES}${id}`);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.DELETE_VENDOR_CATEGORY,
            });
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

export { getVendorCategories, vendorCategoryDetails, addVendorCategoryAction, editVendorCategoryAction, deleteVendorCategory };
