import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import TableCard from "../../shared/TableCard/TableCard";
import { useHistory } from "react-router-dom";
import { classNames } from "primereact/utils";
import { confirmPopup } from "primereact/confirmpopup";
import { useDispatch } from "react-redux";
import { deleteVendorCategory } from "../../redux/actions/vendorCategoryAction";
import Gallery from "../../assets/images/gallery.png";
import { HOST } from "../../constants";

export const VendorCategoryTable = ({ allVendorCategories }) => {
    let history = useHistory();
    let dispatch = useDispatch();

    const deleteRow = (e, id) => {
        confirmPopup({
            target: e.currentTarget,
            message: "Are you sure you want to delete?",
            icon: "pi pi-exclamation-triangle",
            accept: () => {
                dispatch(deleteVendorCategory(id));
            },
            reject: () => {},
        });
    };

    const actions = (data) => {
        return (
            <div className="flex justify-content-around">
                <i className="pi pi-pencil cursor-pointer" onClick={() => history.push(`/vendors/categories/edit/${data._id}`)}></i>
                <i className="pi pi-trash cursor-pointer" onClick={(e) => deleteRow(e, data._id)}></i>
            </div>
        );
    };

    const activeBodyTemplate = (rowData) => {
        return (
            <i className={classNames("pi", { "true-icon pi-check-circle": rowData.isActive, "false-icon pi-times-circle": !rowData.isActive })}></i>
        );
    };

    const imageBodyTemplate = (product) => {
        return <img src={product.icon ? `${HOST}${product.icon}` : Gallery} alt="Icon" className="w-3rem border-round" />;
    };

    return (
        <>
            <TableCard title="Vendor Category List" buttonTitle="Add Category" linkTo={`/vendors/categories/add`}>
                <DataTable
                    paginator
                    value={allVendorCategories}
                    // selection={selectedRow}
                    // onSelectionChange={(e) => setSelectedRow(e.value)}
                    // selectionMode="checkbox"
                    showGridlines
                    // filter={filters}
                    // filterDisplay="menu"
                    rows={10}
                    // loading={loading}
                    emptyMessage="No category found."
                >
                    <Column field="icon" header="Icon" body={imageBodyTemplate}></Column>
                    <Column field="name" sortable header="Name"></Column>
                    <Column field="isActive" header="Active" dataType="boolean" style={{ minWidth: "6rem" }} body={activeBodyTemplate} />
                    <Column body={actions} style={{ width: "80px" }} header="Action"></Column>
                </DataTable>
            </TableCard>
        </>
    );
};
