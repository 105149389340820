import api from "../../services/api";
import Constants from "../../services/constant";
import { hideLoaderAction, showLoaderAction } from "./loaderAction";
import { types } from "../types/types";
import { showToast } from "./toastAction";

const createSubscriptionType = (data, setLoading, history) => async (dispatch) => {
    dispatch(showLoaderAction());
    setLoading(true);

    const res = await api("post", Constants.END_POINT.SUBSCRIPTION_TYPES, { ...data });
    if (res.success) {
        history.push("/subscription-types/");
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }

    setLoading(false);
    dispatch(hideLoaderAction());
};

const getSubscriptionTypes = () => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.SUBSCRIPTION_TYPES);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.ALL_SUBSCRIPTION_TYPE,
                payload: res.data,
            });
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

const updateSubscriptionType = (id, data, setLoading, history) => async (dispatch) => {
    dispatch(showLoaderAction());
    setLoading(true);

    const res = await api("put", `${Constants.END_POINT.SUBSCRIPTION_TYPES}${id}`, data);
    if (res.success) {
        history.push("/subscription-types");
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }

    setLoading(false);
    dispatch(hideLoaderAction());
};

const subscriptionTypeDetails = (id, returnData) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", `${Constants.END_POINT.SUBSCRIPTION_TYPES}${id}`);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.SUBSCRIPTION_TYPE_DETAILS,
                payload: res.data,
            });
        }
        if (returnData) {
            returnData(res.data);
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

const deleteSubscriptionType = (id) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("delete", `${Constants.END_POINT.SUBSCRIPTION_TYPES}${id}`);
    if (res.success) {
        if (res.data) {
            dispatch(getSubscriptionTypes());
            dispatch({
                type: types.DELETE_SUBSCRIPTION_TYPE,
            });
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

export { createSubscriptionType, getSubscriptionTypes, updateSubscriptionType, subscriptionTypeDetails, deleteSubscriptionType };
