import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserSubscriptions } from "../../../redux/actions/userSubscriptionAction";
import UserSubscriptionTable from "./UserSubscriptionTable";

const UserSubscriptions = () => {
    const dispatch = useDispatch();

    const { allUserSubscriptions, successDelete } = useSelector((state) => state.userSubscriptions);

    useEffect(() => {
        dispatch(getUserSubscriptions());
    }, [dispatch, successDelete]);

    return (
        <div>
            {" "}
            <UserSubscriptionTable heading="User Subscriptions" allUserSubscriptions={allUserSubscriptions} />{" "}
        </div>
    );
};
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};
export default React.memo(UserSubscriptions, comparisonFn);
