import api from "../../services/api";
import Constants from "../../services/constant";
import { hideLoaderAction, showLoaderAction } from "./loaderAction";
import { types } from "../types/types";
import { showToast } from "./toastAction";

const getHosts = () => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.HOSTS);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.ALL_HOSTS,
                payload: res.data,
            });
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

const hostDetails = (id, returnData) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", `${Constants.END_POINT.HOSTS}${id}`);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.HOST_DETAILS,
                payload: res.data,
            });
            if (returnData) {
                returnData(res.data);
            }
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

const hostDelete = (id) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("delete", `${Constants.END_POINT.HOSTS}${id}`);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.DELETE_HOST,
                payload: res.data,
            });
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

export { getHosts, hostDetails, hostDelete };
