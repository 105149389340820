import React, { useState, useEffect } from "react";
import { CustomDropDown, CustomForm, CustomInput, CustomInputNumber, CustomInputSwitch, CustomTextArea } from "../../../shared/Input/AllInputs";
import PrimaryButton from "../../../shared/Button/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { allValidations } from "../../../utils/formValidations";
import { showFormErrors } from "../../../utils/commonFunctions";
import { createSubscriptionType, subscriptionTypeDetails, updateSubscriptionType } from "../../../redux/actions/subscriptionTypeAction";
import { getVendorCategories } from "../../../redux/actions/vendorCategoryAction";
import { getFeatures } from "../../../redux/actions/featuresActions";
import { Checkbox } from "primereact/checkbox";

const SubscriptionTypeForm = ({ type }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [selectedFeatures, setSelectedFeatures] = useState([]);
    const { id } = useParams();
    const { allVendorCategories } = useSelector((state) => state.vendorCategories);
    const allVendorCategoriesList = allVendorCategories?.map((item) => ({ _id: item._id, name: item.name }));
    const [data, setData] = useState({
        subscriptionName: "",
        vendorType: allVendorCategoriesList?.[0],
        subscriptionPlan: "Pro",
        subscriptionType: "year",
        subscriptionDescription: "",
        price: "",
        offeredPrice: 100,
        features: [],
        isActive: true,
    });

    const features = useSelector((state) => state.features.allFeatures);
    useEffect(() => {
        dispatch(getVendorCategories());
    }, []);

    useEffect(() => {
        if (id) {
            dispatch(
                subscriptionTypeDetails(id, (data) => {
                    if (data.vendorType) {
                        let dataVendorType = allVendorCategories?.find((item) => item._id === data.vendorType);
                        data.vendorType = { _id: dataVendorType._id, name: dataVendorType.name };
                    }
                    setData(data);
                    setSelectedFeatures(data?.features);
                    dispatch(getFeatures(data?.subscriptionPlan));
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, dispatch]);

    useEffect(() => {
        if (data?.subscriptionPlan === "Standard") {
            const newSubName = data?.subscriptionPlan;
            setData((prevData) => ({ ...prevData, subscriptionName: newSubName }));
        } else {
            const newSubName = `${data?.subscriptionPlan}${data?.vendorType ? `-${data?.vendorType?.name}` : ""}${
                data?.offeredPrice ? `-${data?.offeredPrice}` : data?.price ? `-${data?.price}` : ""
            }`;
            setData((prevData) => ({ ...prevData, subscriptionName: newSubName }));
        }
    }, [data.vendorType, data.subscriptionPlan, data.price, data.offeredPrice]);

    const [loading, setLoading] = useState(false);

    const handleChange = ({ name, value }) => {
        console.log("value", value);
        if (type !== "VIEW") {
            const formErrors = allValidations(name, value, data);
            setData((prev) => ({
                ...prev,
                [name]: value,
                formErrors: prev.formErrors ? { ...prev.formErrors, [name]: formErrors[name] } : null,
            }));
        }
        console.log("name, value", name, value);
        if (name === "subscriptionPlan") {
            dispatch(getFeatures(value));
        }
    };

    const handleChangeFeature = (e) => {
        let Features = [...selectedFeatures];
        if (e.checked) Features.push(e.value);
        else Features.splice(Features.indexOf(e.value), 1);
        setSelectedFeatures(Features);
    };

    const onSubmit = async () => {
        // const trimmedFeatures = data.features.filter((feature) => feature.trim() !== "");

        const updatedData = {
            ...data,
            features: selectedFeatures,
        };

        if (updatedData.offeredPrice > updatedData.price) {
            updatedData.offeredPrice = updatedData.price;
        }

        let ignoreFields = [];

        updatedData.formErrors = {};

        if (updatedData?.subscriptionPlan === "Standard") {
            const newSubName = updatedData?.subscriptionPlan;
            updatedData.subscriptionName = newSubName;

            delete updatedData.price;
            delete updatedData.offeredPrice;
            delete updatedData.vendorType;
            delete updatedData.subscriptionType;
        } else {
            const newSubName = `${updatedData?.subscriptionPlan}${updatedData?.vendorType ? `-${updatedData?.vendorType?.name}` : ""}${
                updatedData?.offeredPrice ? `-${updatedData?.offeredPrice}` : updatedData?.price ? `-${updatedData?.price}` : ""
            }`;
            updatedData.subscriptionName = newSubName;
        }

        if (showFormErrors(updatedData, setData, ignoreFields)) {
            if (type === "ADD") {
                dispatch(createSubscriptionType(updatedData, setLoading, history));
            }
            if (type === "EDIT") {
                dispatch(updateSubscriptionType(id, updatedData, setLoading, history));
            }
        }
    };

    const allTypes = [
        // { name: "Monthly", value: "month" },
        { name: "Yearly", value: "year" },
    ];

    const allSubPlans = [
        { name: "Standard", value: "Standard" },
        { name: "Premium", value: "Premium" },
        { name: "Pro", value: "Pro" },
    ];

    return (
        <CustomForm style={type === "VIEW" ? { pointerEvents: "none" } : {}}>
            <CustomInput label="Subscription Name" name="subscriptionName" data={data} disabled col={12} />
            <CustomDropDown label="Membership Plan" data={data} name="subscriptionPlan" onChange={handleChange} options={allSubPlans} col={12} />
            {data?.subscriptionPlan !== "Standard" && (
                <>
                    <CustomDropDown
                        label="Vendor Type"
                        data={data}
                        name="vendorType"
                        onChange={handleChange}
                        options={allVendorCategoriesList}
                        col={12}
                    />
                    <CustomInputNumber decimalRequired label="Subscription Price" name="price" data={data} onChange={handleChange} col={12} />
                    <CustomInputNumber decimalRequired label="Offered Price" name="offeredPrice" data={data} onChange={handleChange} col={12} />
                    <CustomDropDown
                        label="Subscription Type"
                        data={data}
                        name="subscriptionType"
                        onChange={handleChange}
                        options={allTypes}
                        col={12}
                    />
                </>
            )}
            <CustomTextArea label="Subscription Description" name="subscriptionDescription" data={data} onChange={handleChange} col={12} />
            <div className="w-full">
                <h3>Features</h3>
                <div className="flex flex-wrap gap-5 mb-3 w-full ">
                    {features?.map((item, index) => {
                        return (
                            <div key={index} className="flex align-items-center col-12 md:col-5">
                                <Checkbox inputId="" name="Feature" value={item._id} onChange={handleChangeFeature} checked={true} disabled />
                                <label htmlFor="ingredient1" className="ml-2">
                                    {item.title}
                                </label>
                            </div>
                        );
                    })}
                </div>
            </div>

            {type !== "VIEW" && <PrimaryButton label="Save Subscription Type" loading={loading} onClick={onSubmit} col={12} />}
        </CustomForm>
    );
};

export default SubscriptionTypeForm;
