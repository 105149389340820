import React from "react";
import CustomCard from "../../../shared/Card/CustomCard";
import SubscriptionTypeForm from "./SubscriptionTypeForm";

export default function AddSubscriptionType() {
    return (
        <CustomCard>
            <SubscriptionTypeForm type="ADD" />
        </CustomCard>
    );
}
