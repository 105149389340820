import { combineReducers } from "redux";
import loaderReducer from "./loaderReducer";
import profileReducer from "./profileReducer";
import toastReducer from "./toastReducer";
import uploadReducer from "./uploadReducer";
import vendorReducer from "./vendorReducer";
import hostReducer from "./hostReducer";
import vendorCategoryReducer from "./vendorCategoryReducer";
import amenitiesReducer from "./amenitiesReducer";
import subscriptionTypesReducer from "./subscriptionTypeReducer";
import discountsReducer from "./discountReducer";
import userSubscriptionsReducer from "./userSubscriptionsReducer";
import featuresReducer from "./features";

export default combineReducers({
    toast: toastReducer,
    loader: loaderReducer,
    profile: profileReducer,
    upload: uploadReducer,
    vendors: vendorReducer,
    vendorCategories: vendorCategoryReducer,
    amenities: amenitiesReducer,
    hosts: hostReducer,
    subscriptionTypes: subscriptionTypesReducer,
    discounts: discountsReducer,
    userSubscriptions: userSubscriptionsReducer,
    features: featuresReducer,
});
