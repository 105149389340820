import React, { useEffect, useState } from "react";
import { CustomDropDown, CustomForm, CustomInput, CustomInputSwitch } from "../../shared/Input/AllInputs";
import CustomImageInput from "../../shared/Input/CustomImageInput";
import PrimaryButton from "../../shared/Button/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { allValidations } from "../../utils/formValidations";
import { showFormErrors } from "../../utils/commonFunctions";
import { amenitiesDetails, createAmenities, updateAmenities } from "../../redux/actions/amenitiesAction";
import { getVendorCategories } from "../../redux/actions/vendorCategoryAction";

const AmenitiesForm = ({ type }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { id } = useParams();

    const { allVendorCategories } = useSelector((state) => state.vendorCategories);

    const [data, setData] = useState({
        name: "",
        icon: "",
        vendorType: "",
        isActive: true,
    });
    const ignoreFields = ["icon"];

    useEffect(() => {
        dispatch(getVendorCategories());

        if (id) {
            dispatch(
                amenitiesDetails(id, (data) => {
                    if (data.vendorType) {
                        let dataVendorType = allVendorCategories?.find((item) => item._id === data.vendorType);
                        data.vendorType = { _id: dataVendorType._id, name: dataVendorType.name };
                    }
                    data.icon = data.icon ? [data.icon] : "";
                    setData(data);
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, dispatch]);

    const [loading, setLoading] = useState(false);

    const allVendorCategoriesList = allVendorCategories.map((item) => ({ _id: item._id, name: item.name }));

    const handleChange = ({ name, value }) => {
        if (type !== "VIEW") {
            const formErrors = allValidations(name, value, data);
            setData((prev) => ({ ...prev, [name]: value, formErrors }));
        }
    };

    const onSubmit = async () => {
        if (showFormErrors(data, setData, ignoreFields)) {
            if (type === "ADD") {
                dispatch(createAmenities(data, setLoading, history));
            }
            if (type === "EDIT") {
                dispatch(updateAmenities(id, data, setLoading, history));
            }
        }
    };

    return (
        <CustomForm style={type === "VIEW" ? { pointerEvents: "none" } : {}}>
            <CustomImageInput
                label="Upload Icon"
                data={data}
                onFilesChange={handleChange}
                name="icon"
                editable={type !== "VIEW" ? true : false}
                removeable
                col={6}
            />
            <CustomInput label="Name" name="name" data={data} onChange={handleChange} col={12} />
            <CustomDropDown label="Category" data={data} name="vendorType" onChange={handleChange} options={allVendorCategoriesList} col={12} />
            <CustomInputSwitch label="Active" name="isActive" onChange={handleChange} data={data} />

            {type !== "VIEW" && <PrimaryButton label="Save Amenity" loading={loading} onClick={onSubmit} col={12} />}
        </CustomForm>
    );
};

export default AmenitiesForm;
