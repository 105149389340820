import React, { useEffect, useState } from "react";
import CustomCard from "../../shared/Card/CustomCard";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { approveVendor, vendorDetails } from "../../redux/actions/vendorAction";
import { confirmPopup } from "primereact/confirmpopup";
import PrimaryButton from "../../shared/Button/PrimaryButton";
import CustomImageInput from "../../shared/Input/CustomImageInput";

export const ViewVendor = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const { vendor } = useSelector((state) => state.vendors);

    const [data, setData] = useState({
        profileImage: "",
    });

    useEffect(() => {
        dispatch(
            vendorDetails(id, (data) => {
                data.profileImage = data.profileImage ? [data.profileImage] : "";
                setData(data);
            })
        );
    }, [dispatch, id]);

    const approveVendorHandler = (event, id, email) => {
        if (id) {
            confirmPopup({
                target: event.currentTarget,
                message: "Are you sure you want to Approve?",
                icon: "pi pi-exclamation-triangle",
                accept: () => {
                    dispatch(approveVendor(id, { email: email }, history));
                },
                reject: () => {},
            });
        }
    };

    return (
        <>
            <CustomCard title="Vendor Profile" className="bg-white">
                {vendor ? (
                    <>
                        <div className="grid">
                            <CustomImageInput label="Profile Image" data={data} name="profileImage" editable={false} disabled removeable col={4} />
                            <div className="md:col-4">
                                <label className="text-500 text-sm">Business Name</label>
                                <p className="text-dark font-medium">{vendor.businessName}</p>
                            </div>
                            <div className="md:col-4">
                                <label className="text-500 text-sm">Email</label>
                                <p className="text-dark font-medium">{vendor.user?.email}</p>
                            </div>
                            {vendor.mobile && (
                                <div className="md:col-4">
                                    <label className="text-500 text-sm">Telephone Number</label>
                                    <p className="text-dark font-medium">
                                        {vendor.countryCode} {vendor.mobile}
                                    </p>
                                </div>
                            )}
                            <div className="md:col-4">
                                <label className="text-500 text-sm">Vendor Type</label>
                                <p className="text-dark font-medium">{vendor.vendorType?.name}</p>
                            </div>
                            {vendor.addressLine1 && (
                                <div className="md:col-4">
                                    <label className="text-500 text-sm">Address Line 1</label>
                                    <p className="text-dark font-medium">{vendor.addressLine1}</p>
                                </div>
                            )}
                            {vendor.addressLine2 && (
                                <div className="md:col-4">
                                    <label className="text-500 text-sm">Address Line 2</label>
                                    <p className="text-dark font-medium">{vendor.addressLine2}</p>
                                </div>
                            )}
                            {vendor.city && (
                                <div className="md:col-4">
                                    <label className="text-500 text-sm">City</label>
                                    <p className="text-dark font-medium">{vendor.city}</p>
                                </div>
                            )}
                            {vendor.state && (
                                <div className="md:col-4">
                                    <label className="text-500 text-sm">Country</label>
                                    <p className="text-dark font-medium">{vendor.state}</p>
                                </div>
                            )}
                            {vendor.postalCode && (
                                <div className="md:col-4">
                                    <label className="text-500 text-sm">Postal code</label>
                                    <p className="text-dark font-medium">{vendor.postalCode}</p>
                                </div>
                            )}
                            {vendor.isApproved === false && (
                                <PrimaryButton
                                    severity="primary"
                                    className="mt-2 md:mt-2"
                                    onClick={(e) => approveVendorHandler(e, vendor._id, vendor.user?.email)}
                                    label={"Approve"}
                                />
                            )}
                        </div>
                    </>
                ) : (
                    <div>Vendor not found</div>
                )}
            </CustomCard>
        </>
    );
};
