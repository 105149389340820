import { types } from "../types/types";

const intitalState = {
    allSubscriptionTypes: [],
};

const subscriptionTypesReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.ALL_SUBSCRIPTION_TYPE:
            return {
                ...state,
                allSubscriptionTypes: action.payload,
            };
        case types.SUBSCRIPTION_TYPE_DETAILS:
            return {
                ...state,
                subscriptionType: action.payload,
            };
        case types.DELETE_SUBSCRIPTION_TYPE:
            return {
                ...state,
                successDelete: true,
            };
        default:
            return { ...state };
    }
};

export default subscriptionTypesReducer;
