import React from "react";
import CustomCard from "../../shared/Card/CustomCard";
import AmenitiesForm from "./AmenitiesForm";

export default function EditAmenities() {
    return (
        <CustomCard>
            <AmenitiesForm type="EDIT" />
        </CustomCard>
    );
}
