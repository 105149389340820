import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useState } from "react";
import TableCard from "../../../shared/TableCard/TableCard";
import { useHistory } from "react-router-dom";
import { deleteSubscriptionType } from "../../../redux/actions/subscriptionTypeAction";
import { confirmPopup } from "primereact/confirmpopup";
import { useDispatch } from "react-redux";

export const SubscriptionTypeTable = ({ allSubscriptionType }) => {
    let history = useHistory();
    const dispatch = useDispatch();

    const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState(null);

    const actions = (data) => {
        return (
            <div className="flex justify-content-around">
                <i className="pi pi-eye cursor-pointer m-1" onClick={() => history.push(`/subscription-types/view/${data._id}`)}></i>
                <i className="pi pi-pencil cursor-pointer m-1" onClick={() => history.push(`/subscription-types/edit/${data._id}`)}></i>
                <i className="pi pi-trash cursor-pointer m-1" onClick={(e) => deleteRow(e, data._id)}></i>
            </div>
        );
    };
    const deleteRow = (e, id) => {
        confirmPopup({
            target: e.currentTarget,
            message: "Are you sure you want to delete?",
            icon: "pi pi-exclamation-triangle",
            accept: () => {
                dispatch(deleteSubscriptionType(id));
            },
            reject: () => {},
        });
    };
    const vendorTypeTemplate = (rowData) => {
        return rowData.vendorType ? rowData.vendorType?.name : "N/A";
    };

    const priceTemplate = (rowData) => {
        return rowData.price ? rowData.price : "N/A";
    };

    const offeredPriceTemplate = (rowData) => {
        return rowData.offeredPrice ? rowData.offeredPrice : "N/A";
    };

    const subscriptionPlanOptions = [
        { label: "All", value: null },
        { label: "Standard", value: "Standard" },
        { label: "Premium", value: "Premium" },
        { label: "Pro", value: "Pro" },
    ];

    const handleSubscriptionPlanChange = (e) => {
        console.log(e);
        setSelectedSubscriptionPlan(e ? e : "");
    };

    const filteredData = selectedSubscriptionPlan
        ? allSubscriptionType.filter((item) => item.subscriptionPlan === selectedSubscriptionPlan)
        : allSubscriptionType;

    return (
        <>
            <TableCard
                title="Subscription Type List"
                buttonTitle="Add Subscription Type"
                linkTo={`/subscription-types/add`}
                dropdownOptions={subscriptionPlanOptions}
                onDropdownChange={handleSubscriptionPlanChange}
            >
                <DataTable paginator value={filteredData} showGridlines filterDisplay="menu" rows={10} emptyMessage="No Subscription Type found.">
                    <Column field="subscriptionName" sortable header="Name"></Column>
                    <Column field="subscriptionPlan" sortable header="Plan"></Column>
                    <Column body={vendorTypeTemplate} sortable header="Vendor Type"></Column>
                    <Column field="price" body={priceTemplate} sortable header="Price"></Column>
                    <Column field="offeredPrice" body={offeredPriceTemplate} sortable header="Offered Price"></Column>
                    <Column body={actions} style={{ width: "80px" }} header="Actions"></Column>
                </DataTable>
            </TableCard>
        </>
    );
};
