import { types } from "../types/types";

const intitalState = {
    allVendorCategories: [],
};

const vendorCategoryReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.VENDOR_CATEGORIES:
            return {
                ...state,
                allVendorCategories: action.payload,
            };
        case types.VENDOR_CATEGORY_DETAILS:
            return {
                ...state,
                vendorCategoryDetails: action.payload,
            };
        case types.DELETE_VENDOR_CATEGORY:
            return {
                ...state,
                successDelete: true,
            };
        default:
            return { ...state };
    }
};

export default vendorCategoryReducer;
