import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDiscounts } from "../../../redux/actions/discountAction";
import DiscountTable from "./DiscountTable";

const Discounts = () => {
    const dispatch = useDispatch();

    const { allDiscounts, successDelete } = useSelector((state) => state.discounts);

    useEffect(() => {
        dispatch(getDiscounts());
    }, [dispatch, successDelete]);

    return (
        <div>
            {" "}
            <DiscountTable heading="Discounts" allDiscounts={allDiscounts} />{" "}
        </div>
    );
};
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};
export default React.memo(Discounts, comparisonFn);
