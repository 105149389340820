import React, { useState, useEffect } from "react";
import { CustomInput } from "../../../shared/Input/AllInputs";
import PrimaryButton from "../../../shared/Button/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { allValidations } from "../../../utils/formValidations";
import { showFormErrors } from "../../../utils/commonFunctions";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { confirmPopup } from "primereact/confirmpopup";
import { createFeature, deleteFeature, featureDetails, getFeatures, updateFeature } from "../../../redux/actions/featuresActions";
import { Checkbox } from "primereact/checkbox";

const FeaturesForm = ({ type }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [selectedFeatures, setSelectedFeatures] = useState([]);
    const [visible, setVisible] = useState(false);
    // const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [id, setId] = useState();
    const features = useSelector((state) => state?.features?.allFeatures);
    const [data, setData] = useState({
        title: "",
        applicableForSubscriptionType: [],
    });
    const ignoreFields = [];

    useEffect(() => {
        dispatch(getFeatures());

        if (id) {
            dispatch(
                featureDetails(id, (data) => {
                    setData(data);
                    setSelectedFeatures(data.applicableForSubscriptionType);
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, dispatch]);

    const handleChange = ({ name, value }) => {
        if (name === "title") {
            const formErrors = allValidations(name, value, data);
            setData((prev) => ({ ...prev, [name]: value, formErrors }));
        }
        setData((prev) => ({ ...prev, [name]: value }));
    };

    const onSubmit = async () => {
        let newData = {
            title: data.title,
            applicableForSubscriptionType: selectedFeatures,
        };

        if (showFormErrors(data, setData, ignoreFields)) {
            dispatch(createFeature(newData, setLoading, history, setData));
        }
    };
    const UpdateFeatures = () => {
        let newData = {
            title: data.title,
            applicableForSubscriptionType: selectedFeatures,
        };
        dispatch(updateFeature(id, newData));
        setData();
        setId();
    };

    const actions = (data) => {
        return (
            <div className="flex justify-content-around">
                <i
                    className="pi pi-pencil cursor-pointer m-1"
                    onClick={(e) => {
                        setId(data._id);
                        setVisible(true);
                    }}
                ></i>
                <i className="pi pi-trash cursor-pointer m-1" onClick={(e) => deleteRow(e, data._id)}></i>
            </div>
        );
    };

    const deleteRow = (e, id) => {
        confirmPopup({
            target: e.currentTarget,
            message: "Are you sure you want to delete?",
            icon: "pi pi-exclamation-triangle",
            accept: () => {
                dispatch(deleteFeature(id));
            },
            reject: () => {},
        });
    };
    const handleChangeFeature = (e) => {
        let applicableForSubscriptionType = [...selectedFeatures];
        if (e.checked) applicableForSubscriptionType.push(e.value);
        else applicableForSubscriptionType.splice(applicableForSubscriptionType.indexOf(e.value), 1);
        setSelectedFeatures(applicableForSubscriptionType);
    };

    const SubscriptionTypes = [
        {
            title: "Standard",
            value: "Standard",
        },
        {
            title: "Premium",
            value: "Premium",
        },
        {
            title: "Pro",
            value: "Pro",
        },
    ];
    const applicableTemplate = (col, value) => {
        return <i className={`pi ${col.applicableForSubscriptionType?.includes(value) ? "pi-check text-green-400" : "pi-times text-red-400"}`} />;
    };

    return (
        <>
            <Dialog header="Edit" visible={visible} style={{ width: "50vw" }} onHide={() => setVisible(false)}>
                <div className="flex flex-column jusitfy-content-between ">
                    <CustomInput label="Feature" col={3} name="title" data={data} onChange={handleChange} />
                    <div className="h-full align-self-start mt-2">
                        <p className="ml-3">Applicable For</p>
                        <div className="flex">
                            {SubscriptionTypes.map((item, index) => {
                                return (
                                    <div key={index} className="flex align-items-center mx-3 ">
                                        <Checkbox
                                            inputId=""
                                            name="types"
                                            value={item.value}
                                            onChange={handleChangeFeature}
                                            checked={selectedFeatures?.includes(item?.value)}
                                        />
                                        <label htmlFor="ingredient1" className="ml-2">
                                            {item?.title}
                                        </label>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    <div className="mt-2">
                        <PrimaryButton
                            label={"Update"}
                            extraClassName={""}
                            onClick={() => {
                                UpdateFeatures();
                                setData({
                                    title: "",
                                });
                                setVisible(false);
                                setSelectedFeatures([]);
                            }}
                            col={12}
                        />
                        <PrimaryButton
                            label={"Cancel"}
                            extraClassName={""}
                            onClick={() => {
                                setId("");
                                setData({
                                    title: "",
                                });
                                setSelectedFeatures("");
                            }}
                            col={12}
                        />
                    </div>
                </div>
            </Dialog>
            <div className="flex jusitfy-content-between align-items-center ">
                <CustomInput label="Feature" col={3} name="title" data={!visible ? data : {}} onChange={handleChange} />
                <div className="h-full align-self-start mt-2">
                    <p className="ml-3">Applicable For</p>
                    <div className="flex">
                        {SubscriptionTypes?.map((item, index) => {
                            return (
                                <div key={index} className="flex align-items-center mx-3 ">
                                    <Checkbox
                                        inputId=""
                                        name="types"
                                        value={item.value}
                                        onChange={handleChangeFeature}
                                        checked={!visible ? selectedFeatures?.includes(item?.value) : false}
                                    />
                                    <label htmlFor="ingredient1" className="ml-2">
                                        {item?.title}
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className="mt-2">
                    <PrimaryButton label={"Add"} extraClassName={""} onClick={() => onSubmit()} col={12} />
                </div>
            </div>
            <DataTable paginator value={features} showGridlines filterDisplay="menu" rows={10} emptyMessage="No features found.">
                <Column field="title" sortable header="Feature"></Column>
                <Column field="standard" body={(col) => applicableTemplate(col, "Standard")} header="Standard"></Column>
                <Column field="Premium" body={(col) => applicableTemplate(col, "Premium")} header="Premium"></Column>
                <Column field="pro" body={(col) => applicableTemplate(col, "Pro")} header="Pro"></Column>

                <Column body={actions} style={{ width: "80px" }} header="Actions"></Column>
            </DataTable>
        </>
    );
};

export default FeaturesForm;
