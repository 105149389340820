import api from "../../services/api";
import Constants from "../../services/constant";
import { hideLoaderAction, showLoaderAction } from "./loaderAction";
import { types } from "../types/types";
import { showToast } from "./toastAction";

const getDiscounts = () => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.DISCOUNTS);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.ALL_DISCOUNT,
                payload: res.data,
            });
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

const createDiscount = (data, setLoading, history) => async (dispatch) => {
    dispatch(showLoaderAction());
    setLoading(true);

    let subscriptionType = data.subscriptionType._id;

    const res = await api("post", Constants.END_POINT.DISCOUNTS, { ...data, subscriptionType });
    if (res.success) {
        history.push("/discounts");
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }

    setLoading(false);
    dispatch(hideLoaderAction());
};

const discountDetails = (id, returnData) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", `${Constants.END_POINT.DISCOUNTS}${id}`);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.DISCOUNT_DETAILS,
                payload: res.data,
            });
        }
        if (returnData) {
            returnData(res.data);
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

const updateDiscount = (id, data, setLoading, history) => async (dispatch) => {
    dispatch(showLoaderAction());
    setLoading(true);

    const res = await api("put", `${Constants.END_POINT.DISCOUNTS}${id}`, data);
    if (res.success) {
        history.push("/discounts");
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    setLoading(false);
    dispatch(hideLoaderAction());
};

const deleteDiscount = (id) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("delete", `${Constants.END_POINT.DISCOUNTS}${id}`);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.DELETE_DISCOUNT,
            });
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

export { createDiscount, getDiscounts, discountDetails, updateDiscount, deleteDiscount };
