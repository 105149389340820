import api from "../../services/api";
import Constants from "../../services/constant";
import { hideLoaderAction, showLoaderAction } from "./loaderAction";
import { types } from "../types/types";
import { showToast } from "./toastAction";

const getFeatures = (value) => async (dispatch) => {
    console.log("data", value);
    dispatch(showLoaderAction());
    const res = await api("get", value ? `${Constants.END_POINT.FEATURE}?applicableFor=${value}` : Constants.END_POINT.FEATURE);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.ALL_FEATURES,
                payload: res.data,
            });
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

const createFeature = (data, setLoading, history, setData) => async (dispatch) => {
    dispatch(showLoaderAction());
    setLoading(true);
    const res = await api("post", Constants.END_POINT.FEATURE, data);
    if (res.success) {
        setData({ title: "" });
        dispatch(getFeatures());
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    setLoading(false);
    dispatch(hideLoaderAction());
};

const featureDetails = (id, returnData) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", `${Constants.END_POINT.FEATURE}${id}`);
    if (res.success) {
        if (returnData) {
            returnData(res.data);
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

const updateFeature = (id, data) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("put", `${Constants.END_POINT.FEATURE}${id}`, data);
    if (res.success) {
        dispatch(getFeatures());
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

const deleteFeature = (id) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("delete", `${Constants.END_POINT.FEATURE}${id}`);
    if (res.success) {
        if (res.data) {
            dispatch(getFeatures());
            dispatch({
                type: types.DELETE_DISCOUNT,
            });
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

export { createFeature, getFeatures, featureDetails, deleteFeature, updateFeature };
