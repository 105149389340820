import { types } from "../types/types";

const intitalState = {
    allHosts: [],
};

const hostReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.ALL_HOSTS:
            return {
                ...state,
                allHosts: action.payload,
            };
        case types.HOST_DETAILS:
            return {
                ...state,
                host: action.payload,
            };
        case types.DELETE_HOST:
            return {
                ...state,
                successDelete: true,
            };
        default:
            return { ...state };
    }
};

export default hostReducer;
