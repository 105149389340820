import React, { useState, useEffect } from "react";
import { CustomDropDown, CustomForm, CustomInput, CustomInputNumber, CustomInputSwitch, CustomTextArea } from "../../../shared/Input/AllInputs";
import PrimaryButton from "../../../shared/Button/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { allValidations } from "../../../utils/formValidations";
import { showFormErrors } from "../../../utils/commonFunctions";
import { getSubscriptionTypes } from "../../../redux/actions/subscriptionTypeAction";
import { createDiscount, discountDetails, updateDiscount } from "../../../redux/actions/discountAction";

const DiscountForm = ({ type }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { id } = useParams();

    const { allSubscriptionTypes } = useSelector((state) => state.subscriptionTypes);

    const [data, setData] = useState({
        discountType: "percentage",
        discountCode: "",
        discountPercentage: "",
        discountAmount: "",
        discountLimit: "",
        subscriptionType: "",
        isActive: true,
    });
    const ignoreFields = [];

    useEffect(() => {
        dispatch(getSubscriptionTypes());

        if (id) {
            dispatch(
                discountDetails(id, (data) => {
                    if (data.subscriptionType) {
                        let dataSubscriptionType = allSubscriptionTypes?.find((item) => item._id === data.subscriptionType._id);
                        data.subscriptionType = { _id: dataSubscriptionType._id, name: dataSubscriptionType.subscriptionName };
                    }
                    setData(data);
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, dispatch]);

    const [loading, setLoading] = useState(false);

    const handleChange = ({ name, value }) => {
        if (name === "discountType") {
            const formErrors = allValidations(name, value, data);
            setData((prev) => ({ ...prev, discountAmount: 0, formErrors }));
            setData((prev) => ({ ...prev, discountPercentage: 0, formErrors }));
        }
        if (type !== "VIEW") {
            const formErrors = allValidations(name, value, data);
            setData((prev) => ({ ...prev, [name]: value, formErrors }));
        }
    };

    const onSubmit = async () => {
        if (showFormErrors(data, setData, ignoreFields)) {
            if (type === "ADD") {
                dispatch(createDiscount(data, setLoading, history));
            }
            if (type === "EDIT") {
                dispatch(updateDiscount(id, data, setLoading, history));
            }
        }
    };

    const allDiscountType = [
        { name: "Percentage", value: "percentage" },
        { name: "Amount", value: "amount" },
    ];

    const allSubscriptionTypesList = allSubscriptionTypes?.filter((subscription) => subscription.subscriptionPlan !== "Standard")?.map((item) => ({ _id: item._id, name: item.subscriptionName }));

    return (
        <CustomForm style={type === "VIEW" ? { pointerEvents: "none" } : {}}>
            <CustomInput label="Discount Code" name="discountCode" data={data} onChange={handleChange} col={12} />
            <CustomDropDown label="Discount Type" data={data} name="discountType" onChange={handleChange} options={allDiscountType} col={12} />

            {data.discountType === "percentage" && (
                <CustomInputNumber
                    decimalRequired
                    label="Discount Percentage"
                    name="discountPercentage"
                    data={data}
                    onChange={handleChange}
                    col={12}
                />
            )}

            {data.discountType === "amount" && (
                <CustomInputNumber decimalRequired label="Discount Amount" name="discountAmount" data={data} onChange={handleChange} col={12} />
            )}

            <CustomInputNumber label="Discount Limit" name="discountLimit" data={data} onChange={handleChange} col={12} />

            <CustomDropDown
                label="Subscription Type"
                data={data}
                name="subscriptionType"
                onChange={handleChange}
                options={allSubscriptionTypesList}
                col={12}
            />

            {type !== "VIEW" && <PrimaryButton label="Save Discount" loading={loading} onClick={onSubmit} col={12} />}
        </CustomForm>
    );
};

export default DiscountForm;
