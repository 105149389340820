import React from "react";
import { AppRoutes } from "./allRoutes";
import { Route, Redirect } from "react-router-dom";
import { isAuthenticated } from "../services/auth";
import { Switch } from "react-router-dom/cjs/react-router-dom.min";
import NotFound from "../views/NotFound";

export default function ProtectedRoute() {
    const getAuthRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (isAuthenticated()) {
                return (
                    <Route
                        path={prop.path}
                        key={key}
                        render={(routeProps) => {
                            return <prop.component {...routeProps} name={prop.name} />;
                        }}
                        exact={prop.exact}
                    />
                );
            } else return <Redirect to="/" />;
        });
    };
    return (
        <Switch>
            {getAuthRoutes(AppRoutes)}
            <Route path="*" render={(e) => <NotFound {...e} />} />
        </Switch>
    );
}
