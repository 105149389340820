import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import PrimaryButton from "../Button/PrimaryButton";

export default function TableCard({ title, onSearch, searchKeyword, buttonTitle, onClick, linkTo, children, extraButtons, dropdownOptions, onDropdownChange }) {
    const history = useHistory();
    const [selectedOption, setSelectedOption] = useState(null);

    return (
        <div className="label-table">
            {title || onSearch || linkTo || buttonTitle ? (
                <div className="surface-300 py-2 px-3 flex justify-content-between w-full m-0">
                    <div className="text-2xl">{title}</div>
                    <div className="flex justify-content-end flex-wrap">
                        {onSearch ? (
                            <div className="p-input-icon-left mx-2">
                                <i className="pi pi-search" />
                                <InputText value={searchKeyword} onChange={(e) => onSearch(e.target.value)} placeholder="Search" />
                            </div>
                        ) : null}

                        {dropdownOptions ? (
                            <Dropdown
                                value={selectedOption}
                                options={dropdownOptions}
                                onChange={(e) => {
                                    setSelectedOption(e.value);
                                    onDropdownChange(e.value);
                                }}
                                placeholder="Select option"
                                className="mr-2"
                            />
                        ) : null}

                        {linkTo ? (
                            <PrimaryButton severity="secondary" className="mt-2 md:mt-0" onClick={() => history.push(linkTo)} label={buttonTitle} />
                        ) : buttonTitle ? (
                            <PrimaryButton severity="secondary" className="mt-2 md:mt-0" onClick={onClick} label={buttonTitle} />
                        ) : null}

                        {extraButtons &&
                            extraButtons.length > 0 &&
                            extraButtons.map((item, index) => (
                                <PrimaryButton
                                    key={index}
                                    severity="secondary"
                                    className={`mt-2 md:mt-0 ml-2 ${index > 0 ? 'md:ml-2' : ''}`}
                                    onClick={item.onClick}
                                    label={item.buttonTitle}
                                />
                            ))}
                    </div>
                </div>
            ) : null}
            <div className="content-2 overflow-hidden">{children}</div>
        </div>
    );
}
