import React from "react";
import CustomCard from "../../shared/Card/CustomCard";
import VendorCategoryForm from "./VendorCategoryForm";

export default function AddVendorCategory() {
    return (
        <CustomCard>
            <VendorCategoryForm type="ADD" />
        </CustomCard>
    );
}
