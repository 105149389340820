import Dashboard from "../views/Dashboard/Dashboard";
import Profile from "../views/Profile";

// vendor
import MyVendors from "../views/Vendor/MyVendors";
import { ViewVendor } from "../views/Vendor/viewVendor";
import { ViewHost } from "../views/Host/viewHost";
import MyHost from "../views/Host/MyHost";
import VendorCategories from "../views/VendorCategory/VendorCategories";
import AddVendorCategory from "../views/VendorCategory/addVendorCategory";
import EditVendorCategory from "../views/VendorCategory/EditVendorCategory";
import Amenities from "../views/Amenities/Amenities";
import AddAmenities from "../views/Amenities/addAmenities";
import EditAmenities from "../views/Amenities/EditAmenities";
import SubscriptionTypes from "../views/Subscriptions/SubscriptionTypes/SubscriptionTypes";
import { ViewSubscriptionType } from "../views/Subscriptions/SubscriptionTypes/viewSubscriptionType";
import Discounts from "../views/Subscriptions/Discounts/Discounts";
import ViewDiscount from "../views/Subscriptions/Discounts/ViewDiscount";
import UserSubscriptions from "../views/Subscriptions/UserSubscriptions/UserSubscriptions";
import AddSubscriptionType from "../views/Subscriptions/SubscriptionTypes/AddSubscriptionType";
import EditSubscriptionType from "../views/Subscriptions/SubscriptionTypes/EditSubscriptionType";
import AddDiscount from "../views/Subscriptions/Discounts/AddDiscount";
import EditDiscount from "../views/Subscriptions/Discounts/EditDiscount";
import FeaturesForm from "../views/Subscriptions/Features/AddFeatures";

export const AppRoutes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        exact: true,
    },
    {
        path: "/vendors",
        name: "Vendor",
        component: MyVendors,
        exact: true,
    },
    {
        path: "/vendors/categories",
        name: "Vendor Categories",
        component: VendorCategories,
        exact: true,
    },
    {
        path: "/vendors/categories/add",
        name: "Add Vendor Category",
        component: AddVendorCategory,
        exact: true,
    },
    {
        path: "/vendors/categories/edit/:id",
        name: "Edit Vendor Category",
        component: EditVendorCategory,
        exact: true,
    },
    {
        path: "/vendors/amenities",
        name: "Amenities",
        component: Amenities,
        exact: true,
    },
    {
        path: "/vendors/amenities/add",
        name: "Add Amenities",
        component: AddAmenities,
        exact: true,
    },
    {
        path: "/vendors/amenities/edit/:id",
        name: "Edit Amenities",
        component: EditAmenities,
        exact: true,
    },
    {
        path: "/vendors/view/:id",
        name: "Vendor Profile",
        component: ViewVendor,
        exact: true,
    },
    {
        path: "/host",
        name: "Host",
        component: MyHost,
        exact: true,
    },
    {
        path: "/host/view/:id",
        name: "Host View",
        component: ViewHost,
        exact: true,
    },
    {
        path: "/subscription-types",
        name: "Subscription Types",
        component: SubscriptionTypes,
        exact: true,
    },
    {
        path: "/add-features",
        name: "Add Features",
        component: FeaturesForm,
        exact: true,
    },
    {
        path: "/subscription-types/add",
        name: "Add Subscription Type",
        component: AddSubscriptionType,
        exact: true,
    },
    {
        path: "/subscription-types/view/:id",
        name: "View Subscription Type",
        component: ViewSubscriptionType,
        exact: true,
    },
    {
        path: "/subscription-types/edit/:id",
        name: "Edit Subscription Type",
        component: EditSubscriptionType,
        exact: true,
    },
    {
        path: "/discounts",
        name: "Discounts",
        component: Discounts,
        exact: true,
    },
    {
        path: "/discounts/view/:id",
        name: "View Discount",
        component: ViewDiscount,
        exact: true,
    },
    {
        path: "/discounts/add",
        name: "Add Discount",
        component: AddDiscount,
        exact: true,
    },
    {
        path: "/discounts/edit/:id",
        name: "Edit Discount",
        component: EditDiscount,
        exact: true,
    },
    {
        path: "/user-subscriptions",
        name: "User Subscriptions",
        component: UserSubscriptions,
        exact: true,
    },
    {
        path: "/settings",
        name: "Settings",
        component: Profile,
        exact: true,
    },
];
