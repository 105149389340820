import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./reducers/index";
import hardSet from "redux-persist/es/stateReconciler/hardSet";

const initialState = {
    isLoading: false,
    toastInfo: {},
};
const persistConfig = {
    key: "root",
    storage,
    stateReconciler: hardSet,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = [thunk];

const store = createStore(persistedReducer, initialState, compose(applyMiddleware(...middleware), compose));
const persistor = persistStore(store);

export default store;
export { persistor };
