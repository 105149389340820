import React, { useEffect } from "react";
import { VendorTable } from "./VendorTable";
import { useDispatch, useSelector } from "react-redux";
import { getVendors } from "../../redux/actions/vendorAction";

const MyVendors = () => {
    const dispatch = useDispatch();

    const { allVendor, successDelete } = useSelector((state) => state.vendors);

    useEffect(() => {
        dispatch(getVendors());
    }, [dispatch, successDelete]);

    return (
        <div>
            {" "}
            <VendorTable heading="InActive Customers" allVendor={allVendor.vendors} />{" "}
        </div>
    );
};
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};
export default React.memo(MyVendors, comparisonFn);
