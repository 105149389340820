import { types } from "../types/types";

const intitalState = {
    allFeatures: [],
};

const featuresReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.ALL_FEATURES:
            return {
                ...state,
                allFeatures: action.payload,
            };

        default:
            return { ...state };
    }
};

export default featuresReducer;
