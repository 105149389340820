import React, { useEffect } from "react";
import CustomerTable, { HostTable } from "./HostTable";
import Index from "./index";
import { useDispatch, useSelector } from "react-redux";
import { getHosts } from "../../redux/actions/hostAction";
import { types } from "../../redux/types/types";

const MyVendors = () => {
    const dispatch = useDispatch();

    const { allHosts } = useSelector((state) => state.hosts);

    useEffect(() => {
        dispatch(getHosts());
    }, [dispatch]);

    return (
        <div>
            <HostTable heading="Hosts" allHost={allHosts.hosts} />
        </div>
    );
};
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};
export default React.memo(MyVendors, comparisonFn);
