import Home from "../assets/icons/Home.png";
import Vendor from "../assets/icons/Vendor.png";
import HostIcon from "../assets/icons/Host.png";
import Payments from "../assets/icons/Payments.png";
// import Membership from "../assets/icons/Membership Plan.png";
import Setting from "../assets/icons/setting.png";
import ManagePlanIcon from "../assets/icons/Manage Plan.png";
import ManageContentIcon from "../assets/icons/Manage Content.png";
import AmenitiesIcon from "../assets/icons/amenities.png";
import VendorsCategoryIcon from "../assets/icons/Vendors category.png";

const menuItems = () => {
    return [
        {
            items: [
                {
                    iconactive: Home,
                    label: "Home",
                    to: "/dashboard",
                },
                {
                    iconactive: Vendor,
                    label: "Vendor",
                    to: "/vendors",
                },
                {
                    iconactive: VendorsCategoryIcon,
                    label: "Vendor Categories",
                    to: "/vendors/categories",
                },
                {
                    iconactive: AmenitiesIcon,
                    label: "Amenities",
                    to: "/vendors/amenities",
                },
                {
                    iconactive: HostIcon,
                    label: "Host",
                    to: "/host",
                },
                // {
                //     iconactive: Payments,
                //     label: "Subscriptions",
                //     to: "/payments",
                // },
                {
                    iconactive: Payments,
                    // iconactive: Membership,
                    label: "Subscriptions",
                    items: [
                        {
                            iconactive: ManagePlanIcon,
                            label: "Subscription Types",
                            to: "/subscription-types",
                        },
                        {
                            iconactive: ManagePlanIcon,
                            label: "Features",
                            to: "/add-features",
                        },
                        {
                            iconactive: ManagePlanIcon,
                            label: "Discounts",
                            to: "/discounts",
                        },
                        {
                            iconactive: ManagePlanIcon,
                            label: "User Subscriptions",
                            to: "/user-subscriptions",
                        },
                    ],
                },
                {
                    iconactive: Setting,
                    label: "Settings",
                    items: [
                        {
                            iconactive: ManageContentIcon,
                            label: "Manage Content",
                            to: "/manage-content",
                        },
                    ],
                },
            ],
        },
    ];
};

export { menuItems };
