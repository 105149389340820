import React, { useEffect, useState } from "react";
import CustomCard from "../../shared/Card/CustomCard";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { hostDetails } from "../../redux/actions/hostAction";
import CustomImageInput from "../../shared/Input/CustomImageInput";

export const ViewHost = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const { host } = useSelector((state) => state.hosts);

    const [data, setData] = useState({
        profileImage: "",
    });

    useEffect(() => {
        dispatch(
            hostDetails(id, (data) => {
                data.profileImage = data.profileImage ? [data.profileImage] : "";
                setData(data);
            })
        );
    }, [dispatch, id]);

    return (
        <>
            <CustomCard title="Host Profile" className="bg-white">
                {host ? (
                    <div className="grid">
                        <CustomImageInput label="Profile Image" data={data} name="profileImage" editable={false} disabled removeable col={4} />

                        <div className="md:col-4">
                            <label className="text-500 text-sm">Name</label>
                            <p className="text-dark font-medium">{host.name ?? "N/A"}</p>
                        </div>
                        <div className="md:col-4">
                            <label className="text-500 text-sm">Event Type</label>
                            <p className="text-dark font-medium">{host.eventType ?? "N/A"}</p>
                        </div>
                        <div className="md:col-4">
                            <label className="text-500 text-sm">Partner Name</label>
                            <p className="text-dark font-medium">{host.partnerName ?? "N/A"}</p>
                        </div>
                        <div className="md:col-4">
                            <label className="text-500 text-sm">Mobile</label>
                            <p className="text-dark font-medium">{host.mobile ?? "N/A"}</p>
                        </div>
                        {host.weddingLocation && (
                            <>
                                <div className="md:col-4">
                                    <label className="text-500 text-sm">Wedding Location</label>
                                    <p className="text-dark font-medium">{host.weddingLocation ?? "N/A"}</p>
                                </div>
                                <div className="md:col-4">
                                    <label className="text-500 text-sm">Wedding Date</label>
                                    <p className="text-dark font-medium">{host.weddingDate ?? "N/A"}</p>
                                </div>
                            </>
                        )}
                        {host.receptionLocation && (
                            <>
                                <div className="md:col-4">
                                    <label className="text-500 text-sm">Reception Location</label>
                                    <p className="text-dark font-medium">{host.receptionLocation ?? "N/A"}</p>
                                </div>
                                <div className="md:col-4">
                                    <label className="text-500 text-sm">Reception Location</label>
                                    <p className="text-dark font-medium">{host.receptionDate ?? "N/A"}</p>
                                </div>
                            </>
                        )}
                        <div className="md:col-4">
                            <label className="text-500 text-sm">Number of Guests</label>
                            <p className="text-dark font-medium">{host.receptionGuestNumber ?? "N/A"}</p>
                        </div>
                    </div>
                ) : (
                    <div>Host not found</div>
                )}
            </CustomCard>
        </>
    );
};
